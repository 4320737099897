import React, { useEffect } from "react";
import { Link, useNavigate} from "react-router-dom";
import styles from'./css/PageNotFound.module.css';

const PageNotFound = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/');
        }, 5000); // 5초 타이머

        return () => clearTimeout(timer);
    }, [navigate]);
    return (
        <>
        <div className={styles.notfoundContainer}>
            <h1>404</h1>
            <h2>페이지를 찾을 수 없습니다.</h2>
            <p>접속한 URL에 해당하는 페이지가 없습니다.</p>
            <p>5초 후 메인 페이지로 이동합니다.</p>
            <Link to="/" className="home-link">메인 페이지로 이동하기</Link>
        </div>
        </>
    );
};

export default PageNotFound;
