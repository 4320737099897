import React, { useEffect, useState } from "react";
import { db } from "./firebase/firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import { useNavigate  } from 'react-router-dom';
import "./css/Login.css";

const Login = () => {
    const [id, setId] = useState("");
    const [loggedInUser, setLoggedInUser] = useState(null); 
    const [error, setError] = useState("");

    const navigate  = useNavigate();

    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (!storedUserData) {
            navigate('/'); 
        } else {
            setLoggedInUser(JSON.parse(storedUserData));
            navigate('/contents');
        }
    }, [navigate]);

    const handleLogin = async () => {
        try {
            const userDocRef = collection(db, 'User');
            const userDocSnap = await getDocs(userDocRef);

            let userData = null;
            userDocSnap.forEach(doc => {
                const data = doc.data();
                if (data.userID === id) {
                    userData = data;
                }
            });

            if (userData) {
                localStorage.setItem('userData', JSON.stringify(userData));
                navigate('/contents');
            } else {
                setError("존재하지 않는 아이디입니다.");
            }
        } catch(err) {
            setError("로그인 실패 " + err.message);
        }
    }

    return (
        <div className="login-container">
            <div className="login-box">
            <h2 className="login-title">조선대학교 AIDT</h2>
                <input
                    type="text"
                    className="login-input"
                    placeholder="ID"
                    value={id}
                    onChange={(e) => setId(e.target.value)}
                    onKeyDown={(e) => {if(e.code === 'Enter'){handleLogin()}}}
                    />
                {error && <p className="login-error">{error}</p>}
                <button className="login-button" onClick={handleLogin}>
                    로그인
                </button>
            </div>
        </div>
    );
}

export default Login;