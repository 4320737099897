import React, { useEffect, useState } from "react";
import { Box, Button, Avatar } from "@mui/material";
import { useNavigate } from 'react-router-dom'; 
import { db, storage } from "./firebase/firebaseConfig";
import { collection, getDocs, doc, updateDoc, addDoc, onSnapshot } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Swal from 'sweetalert2';

const Contents = () => {
    const [userData, setUserData] = useState([]);
    const [loggedInUser, setLoggedInUser] = useState(null); 
    const [classes, setClasses] = useState([]);
    const navigate = useNavigate(); 

    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (!storedUserData) {
            navigate('/'); 
        } else {
            setLoggedInUser(JSON.parse(storedUserData)); 
            handleLogin();
        }
    }, [navigate]);

    const handleLogin = async () => {
        try {

            await onSnapshot(collection(db, "Class"), (snapshot) => {
                const contentArray = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    img:doc.data().img,
                    ...doc.data(),
                }));

                const newArray = contentArray.sort((a, b) => a.order - b.order)
                setUserData(newArray)
                console.log(contentArray)
                }
            )
            // const userDocRef = collection(db, 'Class');
            // const userDocSnap = await getDocs(userDocRef);
            
            
            // const userDocs = userDocSnap.docs.map(doc => ({
            //     id: doc.id,
            //     img: doc.data().img,
            //     url: doc.data().url,
            //     name: doc.data().name,
            //     status: doc.data().status,
            //     order: doc.data().order,
            // }));

            // setUserData(userDocs);
        } catch (err) {
            console.log(err.message);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('userData'); 
        navigate('/'); 
    };

    const showUserInfo = () => {
        const adminButtons = loggedInUser && loggedInUser.permission === 'admin'
            ? `<button id="admin-page-button" class="swal2-confirm swal2-styled">관리자 페이지 이동</button>`
            : '';

        Swal.fire({
            title: '사용자 정보',
            html: `
            <table style="width: 100%; border-collapse: collapse; border: none;">
                <tr>
                    <td style="padding: 8px; text-align: center;">이름</td>
                    <td style="padding: 8px; text-align: center;">${loggedInUser ? loggedInUser.name : ''}</td>
                </tr>
                <tr>
                    <td style="padding: 8px; text-align: center;">이메일</td>
                    <td style="padding: 8px; text-align: center;">${loggedInUser ? loggedInUser.email : ''}</td>
                </tr>
                <tr>
                    <td style="padding: 8px; text-align: center;">소속</td>
                    <td style="padding: 8px; text-align: center;">${loggedInUser?.affiliation || ''}</td>
                </tr>
            </table>
        `,
            showCancelButton: true,
            confirmButtonText: '로그아웃',
            cancelButtonText: '닫기',
            footer: `${adminButtons}`
        }).then((result) => {
            if (result.isConfirmed) {
                handleLogout();
            }
        });

        Swal.getPopup().addEventListener('click', (event) => {
            if (event.target.id === 'admin-page-button') {
                Swal.close();
                navigate('/admin');
            }
        });
    };

    const handleEditClass = async (classId) => {
        const classData = userData.find(item => item.id === classId);
        
        let isNameChanged = false;
        let isUrlChanged = false;
    
        const { value: formValues } = await Swal.fire({
            title: '수정하기',
            html: `
                <table style="width: 100%; border-collapse: collapse;">
                    <tr>
                        <td style="padding: 8px; text-align: right;">이름</td>
                        <td style="padding: 8px;">
                            <input id="swal-input1" class="swal2-input" placeholder="이름" value="${classData.name}">
                        </td>
                    </tr>
                    <tr>
                        <td style="padding: 8px; text-align: right;">URL</td>
                        <td style="padding: 8px;">
                            <input id="swal-input2" class="swal2-input" placeholder="URL" value="${classData.url}">
                        </td>
                    </tr>
                    <tr>
                        <td style="padding: 8px; text-align: right;">이미지</td>
                        <td style="padding: 8px;">
                            <input id="swal-input3" type="file" accept="image/*">
                        </td>
                    </tr>
                </table>
            `,
            focusConfirm: false,
            showCancelButton: true,
            confirmButtonText: '수정하기',
            cancelButtonText: '닫기',
            preConfirm: () => {
                const name = document.getElementById('swal-input1').value;
                const url = document.getElementById('swal-input2').value;
                const imgFile = document.getElementById('swal-input3').files[0];
    
                isNameChanged = name !== classData.name;
                isUrlChanged = url !== classData.url;
                const isImageChanged = imgFile !== undefined;
    
                if (!isNameChanged && !isUrlChanged && !isImageChanged) {
                    Swal.showValidationMessage('하나 이상의 필드를 수정해야 합니다.');
                    return false; 
                }
                return [name, url, imgFile];
            }
        });
    
        if (formValues) {
            const [name, url, imgFile] = formValues;
            const classRef = doc(db, 'Class', classId);
    
            try {
                let imgUrl = classData.img;
    
                if (imgFile) {
                    const now = new Date();
                    const timestamp = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}_${now.getHours().toString().padStart(2, '0')}-${now.getMinutes().toString().padStart(2, '0')}-${now.getSeconds().toString().padStart(2, '0')}`;
                    
                    const fileName = `${timestamp}_${imgFile.name}`;
                    const storageRef = ref(storage, `images/${fileName}`);
                    
                    await uploadBytes(storageRef, imgFile);
                    imgUrl = await getDownloadURL(storageRef);
                }
    
                await updateDoc(classRef, { 
                    name: isNameChanged ? name : classData.name,
                    url: isUrlChanged ? url : classData.url,
                    img: imgUrl 
                });
    
                Swal.fire('성공!', '클래스 정보가 수정되었습니다.', 'success');
                setTimeout(() => window.location.reload(), 2000);
            } catch (error) {
                Swal.fire('에러!', '데이터를 수정하는 중 문제가 발생했습니다.', 'error');
                console.error('Error updating document:', error);
            }
        }
    };

    const handleToggleStatus = async (classId) => {
        const classData = userData.find(item => item.id === classId);
        const newStatus = classData.status === 'activate' ? 'deactivate' : 'activate';
        const classRef = doc(db, 'Class', classId);
        await updateDoc(classRef, { status: newStatus });
        handleLogin();
    };

    const handleAddClass = async () => {
        const { value: formValues } = await Swal.fire({
            title: '클래스 생성하기',
            html: `
                <table style="width: 100%;">
                    <tr>
                        <td style="padding: 8px; text-align: right;">클래스명</td>
                        <td>
                            <input id="swal-input1" class="swal2-input">
                        </td>
                    </tr>
                    <tr>
                        <td style="padding: 8px; text-align: right;">연결 URL</td>
                        <td>
                            <input id="swal-input2" class="swal2-input">
                        </td>
                    </tr>
                    <tr>
                        <td style="padding: 8px; text-align: right;">이미지 파일</td>
                        <td style="padding: 8px;">
                            <input id="swal-input3" type="file" accept="image/*">
                        </td>
                    </tr>
                    <tr>
                        <input type="radio" name="status" value="activate" ${classes.status === 'activate' ? 'checked' : ''}> 활성화
                        <input type="radio" name="status" value="deactivate" ${classes.status === 'deactivate' ? 'checked' : ''}> 비활성화
                    </tr>
                </table>
            `,
            focusConfirm: false,
            showCancelButton: true,
            confirmButtonText: '생성하기',
            cancelButtonText: '닫기',
            preConfirm: () => {
                const name = document.getElementById('swal-input1').value;
                const url = document.getElementById('swal-input2').value;
                const imgFile = document.getElementById('swal-input3').files[0];
                const status = document.querySelector('input[name="status"]:checked').value;
    
                if (!name || !url || !imgFile || !status) {
                    Swal.showValidationMessage('모든 필드를 입력해야 합니다.');
                    return false;
                }
    
                return { name, url, imgFile, status };
            }
        });
    
        if (formValues) {
            const { name, url, imgFile, status } = formValues;
    
            try {
                const now = new Date();
                const timestamp = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}_${now.getHours().toString().padStart(2, '0')}-${now.getMinutes().toString().padStart(2, '0')}-${now.getSeconds().toString().padStart(2, '0')}`;
                
                const fileName = `${timestamp}_${imgFile.name}`;
                const storageRef = ref(storage, `images/${fileName}`);
    
                await uploadBytes(storageRef, imgFile);
                const imgUrl = await getDownloadURL(storageRef);
    
                await addDoc(collection(db, 'Class'), {
                    name,
                    url,
                    img: imgUrl,
                    status
                });
    
                Swal.fire('성공!', '새 클래스가 추가되었습니다.', 'success');
                handleLogin();
            } catch (err) {
                console.error(err.message);
                Swal.fire('에러!', '클래스를 추가하는 중 문제가 발생했습니다.', 'error');
            }
        }
    };

    const handleMoveUp = async (index) => {
        if (index === 0) return
        const newUserData = [...userData];
        const currentItem = newUserData[index];
        console.log("currentItem")
        console.log(currentItem.order)
        const previousItem = newUserData[index - 1];

        // 순서 변경
        newUserData[index] = previousItem;
        newUserData[index - 1] = currentItem;

        // Firestore에 업데이트
        await updateDoc(doc(db, 'Class', currentItem.id), { order: currentItem.order - 1 });
        await updateDoc(doc(db, 'Class', previousItem.id), { order: previousItem.order + 1 });

        setUserData(newUserData);
        handleLogin();
        // window.location.reload();
    };
    
    const handleMoveDown = async (index) => {
        if (index === userData.length - 1) return;
        const newUserData = [...userData];
        const currentItem = newUserData[index];
        const nextItem = newUserData[index + 1];

        // 순서 변경
        newUserData[index] = nextItem;
        newUserData[index + 1] = currentItem;

        // Firestore에 업데이트
        await updateDoc(doc(db, 'Class', currentItem.id), { order: currentItem.order + 1 });
        await updateDoc(doc(db, 'Class', nextItem.id), { order: nextItem.order - 1 });

        setUserData(newUserData);
        handleLogin();
        // window.location.reload();
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '20px', backgroundColor: '#f0f4fd', minHeight: '100vh' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '42.5%', marginTop: '20px' }}>
                <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>Contents</span>
                <Box onClick={showUserInfo} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                    <Avatar src={loggedInUser?.photoURL} sx={{ marginRight: '8px' }} />
                    <span>{loggedInUser?.name}</span>
                </Box>
            </Box>
    
            {loggedInUser && (loggedInUser.permission === 'admin' || loggedInUser.permission === 'teacher') && (
                <Button variant="contained" onClick={handleAddClass} sx={{ marginTop: '20px' }}>
                    생성하기
                </Button>
            )}
    
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginTop: '20px' }}>
                {userData.filter(item => 
                    loggedInUser && (
                        loggedInUser.permission === 'admin' || 
                        loggedInUser.permission === 'teacher' || 
                        (loggedInUser.permission === 'normal' && item.status === 'activate')
                    )
                ).sort((a, b) => a.order - b.order).map((item, index) => (
                    <Box key={item.id} sx={{ display: 'flex', alignItems: 'center', width: '100%', maxWidth: '780px', padding: '10px', marginBottom: '10px', backgroundColor: '#ffffff', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
                        <img src={item.img} alt={item.name} style={{ borderRadius: '50%', height: '60px', width: '60px', marginRight: '10px' }} />
                        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center' }}>
                            <a rel={"noreferrer noopener"} href={item.url} style={{ textDecoration: 'none', color: '#000', fontSize: '18px', marginBottom: '5px' }} target="_blank">{item.name}</a>
                            <span style={{ fontSize: '14px', color: item.status === 'activate' ? 'green' : 'red' }}>
                                {item.status === 'activate' ? '활성화됨' : '비활성화됨'}
                            </span>
                        </Box>
    
                        {(loggedInUser && (loggedInUser.permission === 'admin' || loggedInUser.permission === 'teacher')) && (
                            <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: 'auto', textAlign: 'right' }}>
                                <Box sx={{ display: 'flex', gap: '5px', marginBottom: '5px' }}>
                                    <Button 
                                        variant="outlined" 
                                        size="small" 
                                        onClick={() => handleMoveUp(index)} 
                                        sx={{ flex: 1, minWidth: '100px' }}
                                    >
                                        위로 이동
                                    </Button>
                                    <Button 
                                        variant="outlined" 
                                        size="small" 
                                        onClick={() => handleEditClass(item.id)} 
                                        sx={{ flex: 1, minWidth: '100px' }} 
                                    >
                                        수정하기
                                    </Button>
                                </Box>
                                <Box sx={{ display: 'flex', gap: '5px' }}>
                                    <Button 
                                        variant="outlined" 
                                        size="small" 
                                        onClick={() => handleMoveDown(index)} 
                                        sx={{ flex: 1, minWidth: '100px' }} 
                                    >
                                        아래로 이동
                                    </Button>
                                    <Button 
                                        variant="outlined" 
                                        size="small" 
                                        onClick={() => handleToggleStatus(item.id)} 
                                        sx={{ flex: 1, minWidth: '100px', color: item.status === 'activate' ? 'red' : 'green', borderColor: item.status === 'activate' ? 'red' : 'green' }}
                                    >
                                        {item.status === 'activate' ? '비활성화' : '활성화'}
                                    </Button>
                                </Box>
                            </Box>
                        )}
                    </Box>
                ))}
            </Box>
        </Box>
    );
    
    
    
};

export default Contents;