import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { useLocation, useNavigate } from 'react-router-dom';
import Login from './Login';
import Contents from './Contents';
import Admin from './Admin';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import PageNotFound from './PageNotFound';


const basename = process.env.PUBLIC_URL;
const root = ReactDOM.createRoot(document.getElementById('root'));

const routes = [
  {
    path: '/',
    element: <Login/>
  },
  {
    path : '/contents',
    element : <Contents />,
  },
  {
    path: '/admin',
    element : <Admin />
  }, {
    path : '*',
    element : <PageNotFound/>
  }
]

const router = createBrowserRouter(routes, {basename: basename} )

const re = document.referrer
console.log(re)


root.render(
  // <React.StrictMode>
   <RouterProvider router={router}> 

    <App />
   </RouterProvider> 
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
