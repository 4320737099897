// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

// Firebase 프로젝트 설정
const firebaseConfig = {
    apiKey: "AIzaSyCltJepmcKjRS19IHM-NYER8W-1Ms5wN1c",
    authDomain: "chosun-aidt.firebaseapp.com",
    projectId: "chosun-aidt",
    storageBucket: "chosun-aidt.appspot.com",
    messagingSenderId: "892639797766",
    appId: "1:892639797766:web:24aff7639dabae43a59987",
    measurementId: "G-ZM9JYLFRMW"
};

// Firebase 초기화
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const db = getFirestore(app);

export { storage, db };
