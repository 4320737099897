import React, { useEffect, useState } from 'react';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Avatar } from '@mui/material';
import { db, storage } from './firebase/firebaseConfig';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc, query, where } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const Admin = () => {
    const [users, setUsers] = useState([]);
    const [classes, setClasses] = useState([]);
    const [loggedInUser, setLoggedInUser] = useState(() => JSON.parse(localStorage.getItem('userData')));
    const [activeTab, setActiveTab] = useState('account');
    const navigate = useNavigate();

    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (!storedUserData) {
            navigate('/');
        } else {
            setLoggedInUser(JSON.parse(storedUserData));
            loadUsers();
            loadClasses(); 
        }
    }, [navigate]);

    const loadUsers = async () => {
        const querySnapshot = await getDocs(collection(db, 'User'));
        const userList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setUsers(userList);
    };

    const loadClasses = async () => {
        const querySnapshot = await getDocs(collection(db, 'Class'));
        const classList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setClasses(classList);
    };

    const handleLogout = () => {
        localStorage.removeItem('userData');
        setLoggedInUser(null);
        navigate('/');
    };

    const showUserInfo = () => {
        Swal.fire({
            title: '사용자 정보',
            html: `
            <table style="width: 100%; border-collapse: collapse; border: none;">
                <tr>
                    <td style="padding: 8px; text-align: center;">이름</td>
                    <td style="padding: 8px; text-align: center;">${loggedInUser?.name || ''}</td>
                </tr>
                <tr>
                    <td style="padding: 8px; text-align: center;">이메일</td>
                    <td style="padding: 8px; text-align: center;">${loggedInUser?.email || ''}</td>
                </tr>
                <tr>
                    <td style="padding: 8px; text-align: center;">소속</td>
                    <td style="padding: 8px; text-align: center;">${loggedInUser?.affiliation || ''}</td>
                </tr>
            </table>
        `,
            showCancelButton: true,
            confirmButtonText: '로그아웃',
            cancelButtonText: '닫기',
            footer: `<button id="contents-page-button" class="swal2-confirm swal2-styled">컨텐츠 페이지 이동</button>`
        }).then((result) => {
            if (result.isConfirmed) {
                handleLogout();
            }
        });
        Swal.getPopup().addEventListener('click', (event) => {
            if (event.target.id === 'contents-page-button') {
                Swal.close();
                navigate('/contents');
            }
        });
    };

    const handleAddUser = async () => {
        const { value: formValues } = await Swal.fire({
            title: '계정 생성',
            html: `
            <table style="width: 100%; border-collapse: collapse;">
                <tr>
                    <td style="padding: 8px; text-align: right;">이름:</td>
                    <td style="padding: 8px;"><input id="swal-input1" class="swal2-input" placeholder="이름"></td>
                </tr>
                <tr>
                    <td style="padding: 8px; text-align: right;">아이디:</td>
                    <td style="padding: 8px;"><input id="swal-input2" class="swal2-input" placeholder="아이디"></td>
                </tr>
                <tr>
                    <td style="padding: 8px; text-align: right;">이메일:</td>
                    <td style="padding: 8px;"><input id="swal-input3" class="swal2-input" placeholder="이메일"></td>
                </tr>
                <tr>
                    <td style="padding: 8px; text-align: right;">소속:</td>
                    <td style="padding: 8px;"><input id="swal-input4" class="swal2-input" placeholder="소속"></td>
                </tr>
                <tr>
                    <td style="padding: 8px; text-align: right;">권한:</td>
                    <td style="padding: 8px;">
                        <label style="margin-right: 10px;"><input type="radio" name="permission" value="admin"> 관리자</label>
                        <label style="margin-right: 10px;"><input type="radio" name="permission" value="teacher"> 교사</label>
                        <label><input type="radio" name="permission" value="normal" checked> 일반</label>
                    </td>
                </tr>
            </table>
        `,
            focusConfirm: false,
            preConfirm: async () => {
                const selectedPermission = document.querySelector('input[name="permission"]:checked').value;
                const name = document.getElementById('swal-input1').value;
                const userID = document.getElementById('swal-input2').value;
                const email = document.getElementById('swal-input3').value;

                const emailExists = await checkIfExists('email', email);
                const userIDExists = await checkIfExists('userID', userID);

                if (emailExists) {
                    Swal.showValidationMessage(`이미 존재하는 이메일입니다.`);
                    return false;
                }

                if (userIDExists) {
                    Swal.showValidationMessage(`이미 존재하는 아이디입니다.`);
                    return false;
                }

                return [name, userID, email, document.getElementById('swal-input4').value, selectedPermission];
            }
        });

        if (formValues) {
            const [name, userID, email, affiliation, permission] = formValues;
            await addDoc(collection(db, 'User'), { name, userID, email, affiliation, permission });
            loadUsers();
        }
    };

    const handleEditUser = async (user) => {
        const { value: formValues } = await Swal.fire({
            title: '계정 수정',
            html: `
            <table style="width: 100%; border-collapse: collapse;">
                <tr>
                    <td style="padding: 8px; text-align: right;">이름:</td>
                    <td style="padding: 8px;"><input id="swal-input1" class="swal2-input" value="${user.name}"></td>
                </tr>
                <tr>
                    <td style="padding: 8px; text-align: right;">아이디:</td>
                    <td style="padding: 8px;"><input id="swal-input2" class="swal2-input" value="${user.userID}"></td>
                </tr>
                <tr>
                    <td style="padding: 8px; text-align: right;">이메일:</td>
                    <td style="padding: 8px;"><input id="swal-input3" class="swal2-input" value="${user.email}"></td>
                </tr>
                <tr>
                    <td style="padding: 8px; text-align: right;">소속:</td>
                    <td style="padding: 8px;"><input id="swal-input4" class="swal2-input" value="${user.affiliation}"></td>
                </tr>
                <tr>
                    <td style="padding: 8px; text-align: right;">권한:</td>
                    <td style="padding: 8px;">
                        <label style="margin-right: 10px;"><input type="radio" name="permission" value="admin" ${user.permission === 'admin' ? 'checked' : ''}> 관리자</label>
                        <label style="margin-right: 10px;"><input type="radio" name="permission" value="teacher" ${user.permission === 'teacher' ? 'checked' : ''}> 교사</label>
                        <label><input type="radio" name="permission" value="normal" ${user.permission === 'normal' ? 'checked' : ''}> 일반</label>
                    </td>
                </tr>
            </table>
        `,
            focusConfirm: false,
            preConfirm: async () => {
                const email = document.getElementById('swal-input3').value;
                const userID = document.getElementById('swal-input2').value;

                const emailExists = await checkIfExists('email', email, user.id);
                const userIDExists = await checkIfExists('userID', userID, user.id);

                if (emailExists) {
                    Swal.showValidationMessage(`이미 존재하는 이메일입니다.`);
                    return false;
                }

                if (userIDExists) {
                    Swal.showValidationMessage(`이미 존재하는 아이디입니다.`);
                    return false;
                }

                return [
                    document.getElementById('swal-input1').value,
                    userID,
                    email,
                    document.getElementById('swal-input4').value,
                    document.querySelector('input[name="permission"]:checked').value
                ];
            }
        });

        if (formValues) {
            const [name, userID, email, affiliation, permission] = formValues;
            const userDoc = doc(db, 'User', user.id);
            await updateDoc(userDoc, { name, userID, email, affiliation, permission });
            loadUsers();
        }
    };

    const handleDeleteUser = async (user) => {
        Swal.fire({
            title: '정말 삭제하시겠습니까?',
            text: "이 작업은 되돌릴 수 없습니다!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '삭제',
            cancelButtonText: '취소'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await deleteDoc(doc(db, 'User', user.id));
                Swal.fire('삭제되었습니다!', '계정이 성공적으로 삭제되었습니다.', 'success');
                loadUsers();
            }
        });
    };

    const handleAddClass = async () => {
        const { value: formValues } = await Swal.fire({
            title: '클래스 생성',
            html: `
            <table style="width: 100%; border-collapse: collapse;">
                <tr>
                    <td style="padding: 8px; text-align: right;">클래스 이름:</td>
                    <td style="padding: 8px;"><input id="swal-input1" class="swal2-input" placeholder="클래스 이름"></td>
                </tr>
                <tr>
                    <td style="padding: 8px; text-align: right;">URL:</td>
                    <td style="padding: 8px;"><input id="swal-input2" class="swal2-input" placeholder="URL"></td>
                </tr>
                <tr>
                    <td style="padding: 8px; text-align: right;">이미지 파일</td>
                    <td style="padding: 8px;">
                        <input id="swal-input3" type="file" accept="image/*">
                    </td>
                </tr>
                <tr>
                    <input type="radio" name="status" value="activate" ${classes.status === 'activate' ? 'checked' : ''}> 활성화
                    <input type="radio" name="status" value="deactivate" ${classes.status === 'deactivate' ? 'checked' : ''}> 비활성화
                </tr>
            </table>
        `,
            focusConfirm: false,
            preConfirm: () => {
                const name = document.getElementById('swal-input1').value;
                const url = document.getElementById('swal-input2').value;
                const status = document.querySelector('input[name="status"]:checked').value;
                const imgFile = document.getElementById('swal-input3').files[0];
                return [name, url, status, imgFile];
            }
        });

        if (formValues) {
            const [name, url, status, imgFile] = formValues;

            try {
                const imgRef = ref(storage, `images/${uuidv4()}_${imgFile.name}`);
                const snapshot = await uploadBytes(imgRef, imgFile);
                const downloadURL = await getDownloadURL(snapshot.ref);
                await addDoc(collection(db, 'Class'), { name, url, img:downloadURL, status });
                Swal.close();
                Swal.fire('성공!', '클래스가 생성되었습니다.', 'success');
                setTimeout(() => window.location.reload(), 2000);
            } catch (error) {
                Swal.fire('오류', '이미지 업로드 중 문제가 발생했습니다.', 'error');
            }

        }
    };

    const handleEditClass = async (classItem) => {

        let isNameChanged = false;
        let isUrlChanged = false;
        let isStatusChanged = false;

        const { value: formValues } = await Swal.fire({
            title: '클래스 수정',
            html: `
            <table style="width: 100%; border-collapse: collapse;">
                <tr>
                    <td style="padding: 8px; text-align: right;">클래스 이름:</td>
                    <td style="padding: 8px;"><input id="swal-input1" class="swal2-input" value="${classItem.name}"></td>
                </tr>
                <tr>
                    <td style="padding: 8px; text-align: right;">URL:</td>
                    <td style="padding: 8px;"><input id="swal-input2" class="swal2-input" value="${classItem.url}"></td>
                </tr>
                <tr>
                    <td style="padding: 8px; text-align: right;">이미지</td>
                    <td style="padding: 8px;">
                        <input id="swal-input3" type="file" accept="image/*">
                    </td>
                </tr>
                <tr>
                    <input type="radio" name="status" value="activate" ${classItem.status === 'activate' ? 'checked' : ''}> 활성화
                    <input type="radio" name="status" value="deactivate" ${classItem.status === 'deactivate' ? 'checked' : ''}> 비활성화
                </tr>
            </table>
        `,
            focusConfirm: false,
            preConfirm: () => {
                const name = document.getElementById('swal-input1').value;
                const url = document.getElementById('swal-input2').value;
                const imgFile = document.getElementById('swal-input3').files[0];
                const  status = document.querySelector('input[name="status"]:checked').value;

                isNameChanged = name !== classItem.name;
                isUrlChanged = url !== classItem.url;
                const isImageChanged = imgFile !== undefined;
                isStatusChanged = status !== classItem.status;

                if(!isImageChanged && !isNameChanged && !isStatusChanged && !isUrlChanged) {
                    Swal.showValidationMessage('하나 이상의 필드를 수정해야 합니다.');
                    return false; 
                }

                return [name, url, imgFile, status ];
            }
        });

        if (formValues) {
            const [name, url, imgFile, status] = formValues;
            const classRef = doc(db, 'Class', classItem.id);
            try {
                let imgUrl = classItem.img;
    
                if (imgFile) {
                    const now = new Date();
                    const timestamp = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}_${now.getHours().toString().padStart(2, '0')}-${now.getMinutes().toString().padStart(2, '0')}-${now.getSeconds().toString().padStart(2, '0')}`;
                    
                    const fileName = `${timestamp}_${imgFile.name}`;
                    const storageRef = ref(storage, `images/${fileName}`);
                    
                    await uploadBytes(storageRef, imgFile);
                    imgUrl = await getDownloadURL(storageRef);
                }
    
                await updateDoc(classRef, { 
                    name: isNameChanged ? name : classItem.name,
                    url: isUrlChanged ? url : classItem.url,
                    img: imgUrl,
                    status: isStatusChanged ? status: classItem.status
                });
                Swal.fire('성공!', '클래스 정보가 수정되었습니다.', 'success');
                setTimeout(() => window.location.reload(), 2000);
            } catch (error) {
                Swal.fire('에러!', '데이터를 수정하는 중 문제가 발생했습니다.', 'error');
                console.error('Error updating document:', error);
            }
        }
    };

    const handleDeleteClass = async (classItem) => {
        Swal.fire({
            title: '정말 삭제하시겠습니까?',
            text: "이 작업은 되돌릴 수 없습니다!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '삭제',
            cancelButtonText: '취소'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await deleteDoc(doc(db, 'Class', classItem.id));
                Swal.fire('삭제되었습니다!', '클래스가 성공적으로 삭제되었습니다.', 'success');
                loadClasses();
            }
        });
    };

    const checkIfExists = async (field, value, excludeId = null) => {
        const q = query(collection(db, 'User'), where(field, '==', value));
        const querySnapshot = await getDocs(q);
        const docs = querySnapshot.docs;
        if (docs.length === 0) return false;
        if (excludeId) return docs.some((doc) => doc.id !== excludeId);
        return true;
    };

    const renderTable = () => {
        if (activeTab === 'account') {
            return (
                <TableContainer component={Paper} sx={{ marginTop: '20px', width: '80%', overflowX: 'auto' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">연번</TableCell>
                                <TableCell align="center">이름</TableCell>
                                <TableCell align="center">아이디</TableCell>
                                <TableCell align="center">이메일</TableCell>
                                <TableCell align="center">소속</TableCell>
                                <TableCell align="center">권한</TableCell>
                                <TableCell align="center">수정/삭제</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map((user, index) => (
                                <TableRow key={user.id}>
                                    <TableCell align="center">{index + 1}</TableCell>
                                    <TableCell align="center">{user.name}</TableCell>
                                    <TableCell align="center">{user.userID}</TableCell>
                                    <TableCell align="center">{user.email}</TableCell>
                                    <TableCell align="center">{user.affiliation}</TableCell>
                                    <TableCell align="center">
                                        {user.permission === 'admin' ? '관리자' : 
                                        user.permission === 'teacher' ? '선생님' : 
                                        '일반'}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Button variant="outlined" color="primary" onClick={() => handleEditUser(user)}>수정</Button>
                                        <Button variant="outlined" color="secondary" onClick={() => handleDeleteUser(user)} style={{ marginLeft: '8px' }}>삭제</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            );
        } else if (activeTab === 'class') {
            return (
                <TableContainer component={Paper} sx={{ marginTop: '20px', width: '80%', overflowX: 'auto' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">연번</TableCell>
                                <TableCell align="center">클래스 이름</TableCell>
                                <TableCell align="center">URL</TableCell>
                                <TableCell align="center">로고</TableCell>
                                <TableCell align="center">상태</TableCell>
                                <TableCell align="center">수정/삭제</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {classes.map((classItem, index) => (
                                <TableRow key={classItem.id}>
                                    <TableCell align="center">{index + 1}</TableCell>
                                    <TableCell align="center">{classItem.name}</TableCell>
                                    <TableCell align="center">{classItem.url}</TableCell>
                                    <TableCell align="center"><img src={classItem.img} alt={classItem.name} style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '50%' }} /></TableCell>
                                    <TableCell align="center">
                                        {classItem.status === 'activate' ? '활성화' : classItem.status === 'deactivate' ? '비활성화' : ''}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Button variant="outlined" color="primary" onClick={() => handleEditClass(classItem)}>수정</Button>
                                        <Button variant="outlined" color="secondary" onClick={() => handleDeleteClass(classItem)} style={{ marginLeft: '8px' }}>삭제</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            );
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '80%', marginTop: '20px' }}>
                <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>Admin</span>
                <Box onClick={showUserInfo} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                    <Avatar src={loggedInUser?.photoURL} sx={{ marginRight: '8px' }} />
                    <span>{loggedInUser?.name}</span>
                </Box>
            </Box>
            <Box sx={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                <Button variant={activeTab === 'account' ? 'contained' : 'outlined'} onClick={() => setActiveTab('account')}>
                    계정 관리
                </Button>
                <Button variant={activeTab === 'class' ? 'contained' : 'outlined'} onClick={() => setActiveTab('class')} style={{ marginLeft: '8px' }}>
                    클래스 관리
                </Button>
            </Box>
            {renderTable()}
            <Box sx={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end', width: '80%' }}>
                {activeTab === 'account' && (
                    <Button variant="contained" color="primary" onClick={handleAddUser}>
                        계정 생성
                    </Button>
                )}
                {activeTab === 'class' && (
                    <Button variant="contained" color="primary" onClick={handleAddClass}>
                        클래스 생성
                    </Button>
                )}
            </Box>
        </Box>
    );
};

export default Admin;