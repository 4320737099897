import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Login from "./Login";
import Contents from "./Contents";
import Admin from "./Admin"
import PageNotFound from "./PageNotFound"

function App() {

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/contents" element={<Contents />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="＊" element={<PageNotFound/>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
